import React, { createContext, useState, useContext } from 'react';
import { nodesGet, createNode, getNode } from './MlNode';

// Create the UserContext
export const BusinessContext = createContext();

// Create a provider component for the UserContext
export const BusinessProvider = ({ children }) => {
    const [businessData, setBusinessData] = useState(null);
    const type = 'businessData'

    // Function to fetch user data based on identity
    const fetchBusinessData = async (identity, businessIdentity) => {
        try {
            if (!businessIdentity) {
                // If businessIdentity is null or undefined, directly create a new node
                setBusinessData(createNode({ title: 'Business', type, identity }));
                return; // Exit the function early
            }
            const node = await getNode(businessIdentity)
            setBusinessData(node); // Set the first node
        } catch (error) {
            console.error('Failed to fetch business data:', error);
        }
    };


    return (
        <BusinessContext.Provider
            value={{
                businessData,
                setBusinessData,
                fetchBusinessData,
            }}
        >
            {children}
        </BusinessContext.Provider>
    );
};

// Custom hook to use the UserContext
export const useBusiness = () => {
    return useContext(BusinessContext);
};
