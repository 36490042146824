import React from 'react';
import { FormGroup, FormControlLabel, Checkbox, Radio, TextField, Stack, RadioGroup, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import CarTypes from './CarTypes';
import Motorisation from './Motorisation';
import BrandModelSelect from '../BelCar/form/BrandModelSelect';
import PowerInput from '../BelCar/base/PowerInput';
import CarStates from './CarStates';
import CarTarifs from './CarTarifs';
import Registration from './Registration';
import Transmission from './Transmission';
import BusinessDropDown from '../../business/BusinessDropDown'

export default function CarSummary({ node, onChange }) {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onChange(name, value);
    };

    const status = node?.status || 'Edition';

    return (
        <FormGroup>
            <Stack width="100%" spacing={1.5} padding={1.5}>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Edition"
                    name="radio-buttons-group"
                    row
                    value={status}
                    onChange={e => onChange('status', e.target.value)}
                >
                    <FormControlLabel value="Edition" control={<Radio />} label="Edition" />
                    <FormControlLabel value="Public" control={<Radio />} label="Public" />
                    <FormControlLabel value="Archive" control={<Radio />} label="Archive" />
                </RadioGroup>

                <TextField
                    label="Prix"
                    name="price"
                    type="number"
                    value={node?.price || ''}
                    onChange={handleInputChange}
                />
                <FormControlLabel
                    control={<Checkbox />}
                    label="Tva Déductible"
                    checked={node?.taxDeductible || false}
                    onChange={e => onChange('taxDeductible', e.target.checked)}
                />
                <BusinessDropDown
                    identity='autodin'
                    type='businessData'
                    value={node?.supplier || ''}
                    onChange={
                        (value) => onChange('supplier', value)
                        } 
                />
                {/*
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Fournisseur</InputLabel>
                    <Select
                        name="supplier"
                        value={node?.supplier || ''}
                        label="Fournisseur"
                        onChange={handleInputChange}
                    >
                        <MenuItem value="1">Aziz</MenuItem>
                        <MenuItem value="2">Philippe</MenuItem>
                        <MenuItem value="3">Chris</MenuItem>
                    </Select>
                </FormControl>
                */}
                <CarTarifs
                    value={node?.tarif || 'Pariculier'}
                    onChange={e => onChange('tarif', e.target.value)}
                />
                <CarStates
                    value={node?.state || ''}
                    onChange={e => onChange('state', e.target.value)}
                />
                <CarTypes
                    value={node?.type || ''}
                    onChange={e => onChange('type', e.target.value)}
                />
                <BrandModelSelect
                    formData={node || {}}
                    handleInputChange={handleInputChange}
                />
                <Registration
                    value={node?.registration || ''}
                    onChange={e => onChange('registration', e.target.value)}
                />
                <Motorisation
                    value={node?.motorisation || ''}
                    onChange={e => onChange('motorisation', e.target.value)}
                />
                <Transmission
                    transmission={node?.transmission || 2}
                    gears={node?.gears !== undefined ? node.gears : 4}
                    onChange={handleInputChange}
                />
                <TextField
                    name="capacity"
                    value={node?.capacity || ''}
                    fullWidth
                    label="Cylindrée"
                    variant="outlined"
                    onChange={handleInputChange}
                    inputProps={{
                        maxLength: 17,
                    }}
                />
                <PowerInput
                    value={node?.power || ''}
                    unit={node?.powerUnit || 'KW'}
                    onChange={e => onChange('power', e.target.value)}
                    onUnitChange={e => onChange('powerUnit', e.target.value)}
                />
                <TextField
                    name="chassisnumber"
                    value={node?.chassisnumber || ''}
                    fullWidth
                    label="N° de chassis"
                    variant="outlined"
                    onChange={handleInputChange}
                    inputProps={{
                        maxLength: 17,
                    }}
                />
                <TextField
                    name="kms"
                    value={node?.kms || ''}
                    fullWidth
                    label="KMS"
                    variant="outlined"
                    onChange={handleInputChange}
                />
                <TextField
                    name='comments'
                    id="outlined-multiline-static"
                    label="Commentaires"
                    value={node?.comments || ''}
                    multiline
                    minRows={10}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Stack>
        </FormGroup>
    );
}
