import React from 'react';
import styled from 'styled-components';

const TabContainer = styled.div`
  display: flex;
  margin-top: 0.2rem;
`;

const TabButton = styled.button`
  flex: 1;
  padding: 10px 20px;
  background-color: ${props => (props.isSelected ? 'white' : '#f0f0f0')};
  color: ${props => (props.isSelected ? 'black' : '#666')};
  border: none;
  border-bottom: ${props => (props.isSelected ? '1.5px solid grey' : 'none')};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #ddd;
  }

  &:focus {
    outline: none;
  }
`;

export default function TabPanel({ tabLabels, selectedTab, onChange }) {
  const handleChange = (index) => {
    if (onChange) {
      onChange(index);
    }
  };

  return (
    <TabContainer>
      {tabLabels.map((label, index) => (
        <TabButton
          key={index}
          isSelected={selectedTab === index}
          onClick={() => handleChange(index)}
        >
          {label}
        </TabButton>
      ))}
    </TabContainer>
  );
}
