import React, { useState, useEffect } from 'react';
import { MenuItem, FormControl, Select, InputLabel, CircularProgress } from '@mui/material';
import { getNodesList } from '../MlModels/MlNode';

const BusinessDropdown = ({ label = "Vendeur", value, onChange, refresh, identity, type }) => {

    const [businesses, setBusinesses] = useState([]);
    const [loading, setLoading] = useState(true);

    const customMatch = {
        identity: identity || 'qwanyx',
        type: type || 'businessData',
    };

    const customProject = {
        businessName: 1,
    };

    useEffect(() => {
        const fetchBusinesses = async () => {
            setLoading(true);
            try {
                const userId = '';
                const businessList = await getNodesList(userId, customMatch, customProject);

                const sortedBusinesses = [...businessList].sort((a, b) => {
                    const nameA = a.businessName?.toLowerCase() || '';
                    const nameB = b.businessName?.toLowerCase() || '';
                    return nameA.localeCompare(nameB);
                });

                setBusinesses(sortedBusinesses);
            } catch (error) {
                console.error('Failed to fetch businesses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBusinesses();
    }, [refresh]);

    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            {loading ? (
                <CircularProgress size={24} />
            ) : (
                <Select
                    value={value || ''}  // Ensure the Select component displays the value
                    onChange={(event) => onChange(event.target.value)}
                >
                    <MenuItem value="" disabled>
                        Select a business
                    </MenuItem>
                    {businesses.map((business) => (
                        <MenuItem key={business.businessName} value={business.businessName}>
                            {business.businessName}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </FormControl>
    );
};

export default BusinessDropdown;
