import React, { useEffect, useState } from 'react';
import { nodePut } from '../MlModels/MlNode';
import { Dialog, DialogActions, DialogTitle, Button, Box, useMediaQuery, useTheme, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TabPanel from '../components/TabPanel';
import BusinessBoardProfile from './BusinessBoardProfile';
import BusinessBoardContact from './BusinessBoardContact';
import BusinessBoardSuperUser from './BusinessBoardSuperUser';
import { useBusiness } from '../MlModels/BusinessContext';
import { useUser } from '../MlModels/UserContext'

const BusinessDashBoard = ({ open, handleClose, backgroundColor = '#f5f5f5', identity, selectedBusinessId, ...restProps }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedTab, setSelectedTab] = useState(0);

    const { businessData, setBusinessData, fetchBusinessData } = useBusiness();
    const { userData } = useUser();

    // Fetch business data every time the dialog opens
    useEffect(() => {
        if (open && identity) {
            console.log("Fetching business data for ID:", selectedBusinessId);
            fetchBusinessData(identity, selectedBusinessId);  // Fetch data when the dialog is opened
        }
    }, [open, identity, selectedBusinessId]);  // Run when the dialog is opened or identity/ID changes

    const handleChange = (field, value) => {
        setBusinessData(prevNode => ({
            ...prevNode,
            [field]: value,
        }));
    };

    const tabLabels = ["Profile", "Contact"];
    const tabContents = [
        <BusinessBoardProfile node={businessData} onChange={handleChange} />,
        <BusinessBoardContact node={businessData} onChange={handleChange} />
    ];

    // Conditionally add the SuperUser tab if the user is a super user
    if (userData?.superUser) {
        tabLabels.push("SuperUser");
        tabContents.push(<BusinessBoardSuperUser node={businessData} onChange={handleChange} />);
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleSave = async () => {
        try {
            await nodePut(businessData);
            handleClose();
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="dashboard-dialog-title"
            fullWidth
            maxWidth="md"
            fullScreen={fullScreen}
            PaperProps={{
                style: {
                    display: 'flex',
                    flexDirection: 'column',
                    height: fullScreen ? '100%' : '95vh',
                },
            }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: 'white',
                    position: 'relative',
                    borderBottom: '1px solid #ccc',
                }}>
                <Typography variant="h5" component="h2">
                    {`Tableau de bord: ${businessData?.firstName || ''} ${businessData?.name || ''}`}
                </Typography>
                <HighlightOffIcon
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        right: '16px',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer'
                    }}
                />
            </DialogTitle>

            <Box sx={{ backgroundColor: backgroundColor, height: '100%' }}>
                <TabPanel
                    tabLabels={tabLabels}
                    tabContents={tabContents}
                    selectedTab={selectedTab}
                    onChange={handleTabChange}
                />
            </Box>
            <DialogActions
                sx={{
                    borderTop: '1px solid #ccc',
                    backgroundColor: 'rgb(250,250,250)',
                }}
            >
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleSave} color="primary">
                    Sauver
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BusinessDashBoard;
