import React, { useEffect } from "react";
import SimplePage from "../appcomponents/SimplePage";
import AutodinFooter from './AutodinFooter';
import AutoDinLogo from "./AutoDinLogo";
import ReactMarkdown from 'react-markdown';
import Page from './AcheterTrucsEtAstucesText'

const MyPage = () => {

    useEffect(() => {
        // Reset the scroll position to the top when the component mounts
        window.scrollTo(0, 0);
    }, []); // The empty dependency array ensures this effect runs only on mount

    return (
        <SimplePage
            header={<AutoDinLogo width='150px' />}
            body={<ReactMarkdown>{Page}</ReactMarkdown>}        // This goes to the body
            overlay={<AutodinFooter />}   // This goes to the overlay
        />
    );
};

export default MyPage;
