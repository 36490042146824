
import { Divider, Avatar, ListItemAvatar, ListItem, Typography, Stack } from '@mui/material';


import ImagePlaceHolder from '../ImageEditorComponent/Garage.webp';
const BusinessCard = ({ key, node, onBusinessClick }) => {

    const { _id, businessName, businessAvatar } = node;

    // Split imageLink and take the first image URL
    //const firstImageLink = imageLink ? imageLink.split('\n')[0].trim() : null;
    //const nodeImageLink = firstImageLink ? (firstImageLink.startsWith('http') ? firstImageLink : `${quanyxStorage}/${firstImageLink}`) : null;
    //const denomination = formatTitle(node.brand, node.model)
    //const sellerType = getSellerType(node.tarif)
    const handleClick = () => {

        onBusinessClick(_id);
    };
    /*
        const info = formatInfoString(
            node.date,
            node.motorisation,
            node.capacity,
            node.kms
        );
    */

    return (
        <>
            <ListItem alignItems="flex-start" onClick={handleClick} button>
                <ListItemAvatar>
                    <Avatar
                        alt={businessName}
                        src={businessAvatar ? `${process.env.REACT_APP_S3_URL_BASE}/${businessAvatar}` : ImagePlaceHolder}
                        sx={{ width: 80, height: 80, borderRadius: 0, marginRight: 1 }} // Set width, height, and borderRadius to make it square
                    />
                </ListItemAvatar>
                <Stack>
                    <Typography
                        variant="h6"
                        mt={0.4}
                    >{businessName}</Typography>
                    <Typography
                        sx={{ fontSize: '14px' }}
                    >{node.brief}</Typography>
                </Stack>
                {/*<VehicleCardDetails
                    info={info}
                    comments={node.comments}
                    denomination={denomination}

                    sellerType={node.tarif}
                    priceVAT={formatPriceVat(node.price)}
                    price={formatPrice(node.price)}
                    taxDeductible={node.taxDeductible}
                />*/}
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    );
};

export default BusinessCard;
