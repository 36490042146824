import React, { useEffect, useState } from 'react';
import { nodePut } from '../../MlModels/MlNode';
import { Dialog, DialogActions, DialogTitle, Button, Box, useMediaQuery, useTheme, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TabPanel from '../TabPanel';
import DashBoardProfile from './DashBoardProfile';
import DashBoardContact from './DashBoardContact';
import DashBoardPro from './DashBoardPro';

import { useUser } from '../../MlModels/UserContext';

const DashBoard = ({ open, handleClose, backgroundColor = '#f5f5f5', identity, ...restProps }) => {
    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedTab, setSelectedTab] = useState(0);

    // Access userData and fetchUserData from the context
    const { userData: node, fetchUserData, setUserData } = useUser();

    // Fetch the user data when the component mounts
    useEffect(() => {
        if (identity) {
            fetchUserData(identity); // Use the context function to fetch data
        }
    }, [identity]);

    // Handle field changes by updating the context state
    const handleChange = (field, value) => {
        setUserData(prevNode => ({
            ...prevNode,
            [field]: value,
        }));
    };

    // Conditionally adjust tab labels and contents based on node.business
    const tabLabels = ["Profile", "Contact"];
    const tabContents = [
        <DashBoardProfile node={node} onChange={handleChange} />,
        <DashBoardContact node={node} onChange={handleChange} />
    ];
    /*
        if (node?.business) {
            tabLabels.push("Pro");
            tabContents.push(<DashBoardPro node={node} onChange={handleChange} />);
        }
    */
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleSave = async () => {
        try {
            await nodePut(node);
            handleClose();
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="dashboard-dialog-title"
            fullWidth
            maxWidth="md"
            fullScreen={fullScreen}
            PaperProps={{
                style: {
                    display: 'flex',
                    flexDirection: 'column',
                    height: fullScreen ? '100%' : '95vh',
                },
            }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: 'white',
                    position: 'relative',
                    borderBottom: '1px solid #ccc',
                }}>
                <Typography variant="h5" component="h2">
                    {`Tableau de bord: ${node?.firstName || ''} ${node?.name || ''}`}
                </Typography>
                <HighlightOffIcon
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        right: '16px',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer'
                    }}
                />
            </DialogTitle>

            <Box sx={{ backgroundColor: backgroundColor, height: '100%' }}>
                <TabPanel
                    tabLabels={tabLabels}
                    tabContents={tabContents}
                    selectedTab={selectedTab}
                    onChange={handleTabChange}
                />
            </Box>
            <DialogActions
                sx={{
                    borderTop: '1px solid #ccc',
                    backgroundColor: 'rgb(250,250,250)',
                }}
            >
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleSave} color="primary">
                    Sauver
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DashBoard;
