import * as React from 'react';
import PropTypes from 'prop-types';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { useEmotion } from './EmotionContext'; // Import the EmotionContext

// Create a require context to dynamically load all images in the directory
const images = require.context('./emotionImages', false, /\.jpg$/);

// Function to extract the emotion name from the filename
function getEmotionFromFilename(filename) {
    return filename.split('_')[2].replace('.jpg', '');
}

// Your image data array
const imageData = images.keys().map((key) => {
    const imgIndex = key.match(/Fa_(\d+)_/)[1];
    const emotion = getEmotionFromFilename(key);

    return {
        img: images(key), // This will give you the path to the image
        title: emotion,
        index: imgIndex,
    };
});

function EmotionGrid({ width, height }) {
    const { skip, collectData, colors, editMode } = useEmotion(); // Destructure skip and collectData from the context

    // Handle image click event
    // Inside EmotionGrid
    const handleImageClick = (image) => {
        if (!editMode) return; // Do nothing if editMode is false
    
        console.log(`Image clicked: ${image.title} (Index: ${image.index})`);
        collectData(image.title); // Collect data when an image is clicked
        skip('next'); // Skip to the next segment when an image is clicked
    };
    
    const handleNeutralClick = () => {
        if (!editMode) return; // Do nothing if editMode is false
    
        console.log('Neutral button clicked');
        collectData('Neutral'); // Collect data when the Neutral button is clicked
        skip('next'); // Skip to the next segment when the Neutral button is clicked
    };    


    return (
        <Box>
            <ImageList sx={{ width: width, height: height }} cols={4} rowHeight={120}>
                {imageData.map((item) => (
                    <ImageListItem key={item.index}>
                        <img
                            src={item.img}
                            alt={item.title}
                            loading="lazy"
                            style={{
                                cursor: 'pointer', height: 64, objectFit: 'contain',
                                backgroundColor: colors[item.title.toLowerCase()], // Apply the color as background
                                 // Blend the background color with the image

                            }} // Set height to 64px
                            onClick={() => handleImageClick(item)} // Add click handler
                           
                        />
                        <ImageListItemBar
                            title={item.title}
                            position="below"
                            sx={{
                                backgroundColor: 'black', // Set background to black
                                color: 'white', // Set text color to white
                                textAlign: 'center', // Center the text
                            }}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            {/* Add the Neutral button below the ImageList */}
            <Button
                variant="contained"
                onClick={handleNeutralClick}
                sx={{ mt: 2 }}
            >
                Neutral
            </Button>
        </Box>
    );
}

// Add PropTypes to ensure width and height are passed correctly
EmotionGrid.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
};

// Default Props in case they are not provided
EmotionGrid.defaultProps = {
    width: '50%',
    height: '70%',
};

export default EmotionGrid;
