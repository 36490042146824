import { useMlCanvas } from '../../MlModels/MlCanvasProvider';
import { Stack } from '@mui/material';
import {
    Container,
    ImageSection,
    ContentSection,
    Title,
    Summary 
} from './qwanyxStyles';

import ImagePlaceHolder from '../../ImageEditorComponent/Vehicle.webp';

const NodeCard = ({ node, onCardClick }) => {
    const { quanyxStorage } = useMlCanvas();
    const { _id, title, imageLink } = node;

    // Split imageLink and take the first image URL
    const firstImageLink = node.imageLink ? node.imageLink.split('\n')[0].trim() : null;
    const nodeImageLink = firstImageLink ? (firstImageLink.startsWith('http') ? firstImageLink : `${quanyxStorage}${firstImageLink}`) : null;

    const handleClick = () => {
        onCardClick(_id);
    };

    return (
        <Container onClick={handleClick}>
            <ImageSection>
                <img
                    alt={node.title}
                    src={nodeImageLink || ImagePlaceHolder}
                />
            </ImageSection>
            <ContentSection>
                <Title>{node.title}</Title>
                <Summary>{node.brief}</Summary>
            </ContentSection>
        </Container>
    );
};

export default NodeCard;
