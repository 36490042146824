import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { getNodesList } from '../MlModels/MlNode';  // Assuming this is where getNodesList is located
import { listFiles } from '../AWS/s3Utils';  // Assuming listFiles is in s3Utils.js
import { useEmotion } from './EmotionContext';


export default function DataTree() {
    const [subjects, setSubjects] = useState([]);
    const [episodes, setEpisodes] = useState([]);  // State for episodes
    const { updateSubjectUrl } = useEmotion(); 
    const keyPrefix = 'smurf/SUBJECTS/66dc26d26f8bbf6ce40a1413';  // Adjusted keyPrefix for S3
    const bucketName = 'qwanyx-storage-images';

    useEffect(() => {
        // Fetch the subjects from the database
        async function fetchSubjects() {
            try {
                // Prepare customMatch and customProject for Subjects
                const customMatch = { identity: 'smurf', form: 'subjectBoard' };
                const customProject = { _id: 1, firstName: 1, name: 1 };

                // Call getNodesList with the customMatch and customProject
                const response = await getNodesList('', customMatch, customProject);

                if (response) {
                    setSubjects(response);  // Store the fetched subjects in the state
                } else {
                    console.error('No subjects found');
                }
            } catch (error) {
                console.error('Error fetching subjects:', error);
            }
        }

        // Fetch episodes from S3 using keyPrefix
        async function fetchEpisodes() {
            try {
                // Call listFiles from s3Utils
                const response = await listFiles(bucketName, keyPrefix);
        
                // Access the array of files under the "files" key
                const files = response.files || [];  // Use response.files if it exists, otherwise default to []
        
                // Filter out the pseudo-folder (size: 0) and map the files
                const filteredFiles = files.filter(file => file.size > 0);
        
                if (filteredFiles.length > 0) {
                    // Map the response to setEpisodes
                    const episodeList = filteredFiles.map(file => ({
                        _id: file.key,  // Use S3 Key as _id
                        title: file.key.replace(keyPrefix + '/', '')  // Strip the keyPrefix and keep the file name
                    }));
                    setEpisodes(episodeList);  // Store the fetched episodes in the state
                } else {
                    console.error('No episodes found');
                }
            } catch (error) {
                console.error('Error fetching episodes:', error);
            }
        }        

        fetchSubjects();
        fetchEpisodes();
    }, []);  // Empty dependency array to fetch data on component mount

    // Handle clicking on a subject
    const handleSubjectClick = (subjectId) => {
        alert(`Subject ID: ${subjectId}`);  // Display the _id in a simple alert
    };

    // Handle clicking on an episode
    const handleEpisodeClick = (episodeId) => {
        updateSubjectUrl(episodeId);

       // alert(`Episode ID: ${episodeId}`);  // Display the _id in a simple alert
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: '100%',
            }}
        >
            {/* Subject List */}
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // Center the title horizontally
                    justifyContent: 'flex-start',  // Align the content to the top
                    paddingTop: '16px'  // Add some padding from the top if needed
                }}
            >
                <Typography variant="h6" align="center" gutterBottom>
                    Subjects
                </Typography>
                <List>
                    {subjects.length > 0 ? (
                        subjects.map((subject, index) => (
                            <ListItem key={subject._id} button onClick={() => handleSubjectClick(subject._id)}>
                                {`${subject.firstName} ${subject.name}`}  {/* Display firstName and name */}
                            </ListItem>
                        ))
                    ) : (
                        <Typography>No subjects available</Typography>
                    )}
                </List>
            </Box>

            {/* Episode List */}
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',  // Align the content to the top
                    paddingTop: '16px'  // Add some padding from the top if needed
                }}
            >
                <Typography variant="h6" align="center" gutterBottom>
                    Episodes
                </Typography>
                <List>
                    {episodes.length > 0 ? (
                        episodes.map((episode, index) => (
                            <ListItem key={episode._id} button onClick={() => handleEpisodeClick(episode._id)}>
                                {episode.title}  {/* Display episode title */}
                            </ListItem>
                        ))
                    ) : (
                        <Typography>No episodes available</Typography>
                    )}
                </List>
            </Box>
        </Box>
    );
}
