import React, { useState, useEffect } from 'react';
import { List, Stack, Paper } from '@mui/material';
import { getNodesList } from '../MlModels/MlNode';
import BusinessCard from './BusinessCard';

const BusinessList = ({ mt = 0, elevation = 0, onRowClick, refresh, searchEmbedding, type, identity }) => {

    const [nodes, setNodes] = useState([]);
    const [loading, setLoading] = useState(true);

    const customMatch = {
        identity: identity || 'qwanyx',
        type: type || 'businessData',
    };

    const customProject = {
        _id: 1,
        businessName: 1,
        brief: 1,
        brief: 1,
        businessAvatar: 1
    };

    useEffect(() => {
        const fetchNodes = async () => {
            setLoading(true);
            try {
                const userId = ''
                const nodesList = await getNodesList(userId, customMatch, customProject);

                const sortedNodes = [...nodesList].sort((a, b) => {
                    const nameA = a.businessName?.toLowerCase() || '';
                    const nameB = b.businessName?.toLowerCase() || '';
                    return nameA.localeCompare(nameB);
                });

                setNodes(sortedNodes);
            } catch (error) {
                console.error('Failed to fetch nodes:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNodes();
    }, [refresh, searchEmbedding]);

    return (
        <Paper
            sx={{ width: '100%', bgcolor: 'rgb(250,250,250)', mt: { mt }, borderRadius: '0px' }}
            elevation={elevation}
        >
            <Stack direction="column" width="100%" maxHeight="100%" overflow="auto" padding={0}>
                <List sx={{ width: '100%', bgcolor: 'rgb(250,250,250)' }}>
                    {nodes.map((node) => (
                        <BusinessCard
                            key={node._id.toString()}
                            node={node}
                            onBusinessClick={() => onRowClick(node._id)}
                        />
                    ))}
                </List>
            </Stack>
        </Paper>
    );
};

export default BusinessList;
