import React, { useState, useEffect } from 'react';
import { List, Stack, Paper } from '@mui/material';
import { getNodesList } from '../../MlModels/MlNode';
import NodeCard from './NodeCard';
import { useAuth } from "../../AuthContext";

const NodeList = ({ mt = 0, elevation = 0, onCardClick, refresh }) => {
    const { currentUser } = useAuth();
    const [nodes, setNodes] = useState([]);
    const [loading, setLoading] = useState(true);

    const userId = currentUser ? currentUser.id : null;
    const bgColor = 'rgb(250,250,250)';
    //const customMatch = userId ? { userId: userId, publicTemplate: true } : { publicTemplate: true };
    //const customMatch = userId ? { public: true } : { public: true };

    const customMatch = {
        public: true,
        tags: { $regex: '(^|,)blog(,|$)', $options: 'i' }
    };

    const customProject = {
        _id: 1,
        title: 1,
        brief: 1,
        createdAt: 1,
        imageLink: 1
    };

    useEffect(() => {
        const fetchNodes = async () => {
            try {
                const nodesList = await getNodesList(userId, customMatch, customProject);
                // Sort nodes by createdAt in descending order
                const sortedNodes = nodesList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setNodes(sortedNodes);
            } catch (error) {
                console.error('Failed to fetch nodes:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNodes();
    }, [userId, refresh]); // Add refresh as a dependency

    return (
        <Paper
            sx={{ width: '100%', bgcolor: bgColor, mt: { mt }, borderRadius: '0px' }}
            elevation={elevation}
        >
            <Stack direction="column" width="100%" maxHeight="100%" overflow="auto" padding={0}>
                <Stack
                    direction="column"
                    width="100%"
                    maxHeight="100%"
                    overflow="auto"
                >
                    <List sx={{ width: '100%', bgcolor: bgColor }}>
                        {nodes.map((node) => (
                            <NodeCard key={node._id.toString()} node={node} onCardClick={onCardClick} />
                        ))}
                    </List>
                </Stack>
            </Stack>
        </Paper>
    );
};

export default NodeList;
