// VehicleSummary.js

import road from '../BelCar/images/road.svg';
import transmission from '../BelCar/images/transmission.svg';
import date from '../BelCar/images/date.svg';
import fuel from '../BelCar/images/fuel.svg';
import gauge from '../BelCar/images/gauge.svg';
import piston from '../BelCar/images/piston-svgrepo-com.svg';
import {
  getTransmissionType,
  formatTransmission,
  getMotorisationType
} from './AutodinUtils';
import {
  RoadIcon,
  Road,
  Title,
  Value,
  Text1,
  Infowithicon1,
  TransmissionIcon,
  Transmission,
  DateIcon,
  FuelIcon,
  GaugeIcon,
  UserIcon,
  Infos,
  Infoframe as InfoframeWrapper,
} from './StyledStyles'; // Adjust the import path according to your project structure

const VehicleSummary = ({ vehicleData }) => (
  <InfoframeWrapper>
    <Infos>
      <Infowithicon1>
        <Road>
          <RoadIcon alt="" src={road} />
        </Road>
        <Text1>
          <Title>Kilométrage</Title>
          <Value>{vehicleData?.kms} km</Value>
        </Text1>
      </Infowithicon1>
      <Infowithicon1>
        <Transmission>
          <TransmissionIcon alt="" src={transmission} />
        </Transmission>
        <Text1>
          <Title>Transmission</Title>
          <Value>{formatTransmission(vehicleData?.transmission, vehicleData?.gears)}</Value>
        </Text1>
      </Infowithicon1>
      <Infowithicon1>
        <Transmission>
          <DateIcon alt="" src={date} />
        </Transmission>
        <Text1>
          <Title>Année</Title>
          <Value>{vehicleData?.registration}</Value>
        </Text1>
      </Infowithicon1>
      <Infowithicon1>
        <Transmission>
          <FuelIcon alt="" src={fuel} />
        </Transmission>
        <Text1>
          <Title>Carburant</Title>
          <Value>{getMotorisationType(vehicleData?.motorisation)}</Value>
        </Text1>
      </Infowithicon1>
      <Infowithicon1>
        <Transmission>
          <GaugeIcon alt="" src={gauge} />
        </Transmission>
        <Text1>
          <Title>Puissance</Title>
          <Value>{vehicleData?.power} {vehicleData?.powerUnit}</Value>
        </Text1>
      </Infowithicon1>
      <Infowithicon1>
        <Transmission>
          <UserIcon alt="" src={piston} />
        </Transmission>
        <Text1>
          <Title>Cylindrée</Title>
          <Value>{vehicleData?.capacity}</Value>
        </Text1>
      </Infowithicon1>
    </Infos>
  </InfoframeWrapper>
);

export default VehicleSummary;
