import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Stack, TextField } from '@mui/material';

export default function BusinessBoardSuperUser({ node, onChange }) {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onChange(name, value);
    };

    return (
        <FormGroup>

            <Stack width="100%" spacing={1.5} padding={1.5}>
{/*
                <FormControlLabel
                    control={
                        <Checkbox
                            name="isPublic"
                            checked={node?.isPublic || false}  // If node.isPublic is true, the checkbox will be checked
                            onChange={(e) => handleInputChange({ target: { name: 'isPublic', value: e.target.checked } })}
                        />
                    }
                    label="Make Business Public"
                />
*/}
                <TextField
                    label="Identifiant unique"
                    name="publicId"
                    value={node?.publicId || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Email de contact"
                    name="contactMail"
                    value={node?.contactMail || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Email info"
                    name="infoMail"
                    value={node?.infoMail || ''}
                    onChange={handleInputChange}
                />
                
            </Stack>
        </FormGroup>
    );
}
