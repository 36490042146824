import { useMlCanvas } from '../../MlModels/MlCanvasProvider';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import {
    formatPrice,
    formatPriceVat,
    formatInfoString,
    formatTitle,
    formatSupplier
} from './AutodinUtils'

import VehicleCardDetails from './VehicleCardDetails'
import ImagePlaceHolder from '../../ImageEditorComponent/Vehicle.webp';
const AutodinCard = ({ node, onCardClick }) => {
    const { quanyxStorage } = useMlCanvas();
    const { _id, title, imageLink } = node;

    // Split imageLink and take the first image URL
    const firstImageLink = imageLink ? imageLink.split('\n')[0].trim() : null;
    const nodeImageLink = firstImageLink ? (firstImageLink.startsWith('http') ? firstImageLink : `${quanyxStorage}/${firstImageLink}`) : null;
    const denomination = formatTitle(node.brand, node.model)
    //const sellerType = getSellerType(node.tarif)
    const handleClick = () => {
        onCardClick(node._id);
    };

    const info = formatInfoString(
        node.registration,
        node.motorisation,
        node.capacity,
        node.kms,
    );


    return (
        <>
            <ListItem alignItems="flex-start" onClick={handleClick} button>
                <ListItemAvatar>
                    <Avatar
                        alt={node.title}
                        src={nodeImageLink || ImagePlaceHolder}
                        sx={{ width: 80, height: 80, borderRadius: 0, marginRight: 1 }} // Set width, height, and borderRadius to make it square
                    />
                </ListItemAvatar>
                <VehicleCardDetails
                    info={info}
                    comments={node.comments}
                    denomination={denomination}
                    sellerType={formatSupplier(node.supplier)}
                    priceVAT={formatPriceVat(node.price)}
                    price={formatPrice(node.price)}
                    taxDeductible={node.taxDeductible}
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    );
};

export default AutodinCard;
