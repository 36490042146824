import React from "react";
import { MlHContainer, MlHBox } from '../mainlistcomponents';
import { ReactComponent as QwanyxLogo } from '../../../components/qwanyx/Qwanyx-logo-white.svg'
import QwanyxLogoWhite from '../../../components/qwanyx/QwanyxLogoWhite'
const QwanyxHeader = (bgColor = '#FFFFFF', logoHeight = '50px') => {
    return (
        <MlHContainer >
            <MlHBox>
            <QwanyxLogoWhite height="25px"/>
            </MlHBox>
        </MlHContainer>
    );
};
export default QwanyxHeader;