import AWS from 'aws-sdk';
import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import { listFiles } from '../AWS/s3Utils'
import { nodePut, createNode, nodeGetOne } from '../MlModels/MlNode'


const EmotionContext = createContext();
const editMode = false
function getIdentity() {
  return 'smurf'; // or any other appropriate value or action
}

export const EmotionProvider = ({ children }) => {
  const [emotionData, setEmotionData] = useState(null);
  const [selectedTimeRatio, setSelectedTimeRatio] = useState(0);
  const [currentEmotionSet, setCurrentEmotionSet] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [subjectNode, setsubjectNode] = useState([]);
  const [collectedData, setCollectedData] = useState([]); // State to store collected data
  const [subjectUrl, setsubjectUrl] = useState([]); // State to store collected data
  const [isScrubbing, setIsScrubbing] = useState(false);
  const [menuTree, setMenuTree] = useState([]); // State to store seasons and episodes
  const videoRefs = useRef([]);
  const S3_URL_BASE = process.env.REACT_APP_S3_URL_BASE;

  useEffect(() => {
    if (subjectUrl) {
      // Only call getData if subjectUrl is set (not null/undefined)
      getData();
    }
  }, [subjectUrl]); // This will trigger whenever subjectUrl changes

  const colors = {
    stressness: '#e99999',
    indignation: '#e58a8a',
    anger: '#d15151',
    rage: '#b52323',
    disdain: '#b9bc73',
    adversion: '#9ea24c',
    disgust: '#848830',
    revulsion: '#4c510c',
    concern: '#ba6eb6',
    anxiety: '#994293',
    fear: '#741f6d',
    terror: '#46083f',
    satisfaction: '#c69511',
    amusement: '#c87511',
    joy: '#c87511',
    laughter: '#9e3601',
    dejection: '#67a3ea',
    melancoly: '#225cd6',
    sadness: '#1141c8',
    grief: '#0627b8',
    alertness: '#40e4cb',
    wonder: '#03ad73',
    surprize: '#008542',
    shock: '#00551a',
  };

  const emotionColors = {
    CALM: '#fffff',
    CONFUSED: '#5d611c',
    DISGUSTED: '#000000',
    SAD: '#233bb5',
    SURPRISED: '#229653',
    ANGRY: '#b52323',
    FEAR: '#ff6600',
    HAPPY: '#ff0080',
  };

  // Parse the bucket name from the S3_URL_BASE
  const bucketName = S3_URL_BASE.split('https://')[1].split('.s3')[0];

  // Initialize AWS S3
  const s3 = new AWS.S3({
    region: 'eu-central-1',
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const rekognition = new AWS.Rekognition();

  const increment = 1 / 250; // The increment value

  const getIdentity = () => 'smurf';

  const setSelectedTime = (normalizedTime) => {
    const clampedTime = Math.min(Math.max(normalizedTime, 0), 1);
    setSelectedTimeRatio(clampedTime);

    if (!emotionData || emotionData.length === 0) return;

    const maxTimestamp = Math.max(...emotionData.map(d => d.timestamp));
    const actualTime = clampedTime * maxTimestamp;

    updateCurrentEmotionSet(actualTime);

    videoRefs.current.forEach(videoRef => {
      if (videoRef && !isPlaying) {
        videoRef.currentTime = clampedTime * videoRef.duration;
      }
    });
  };

  const getData = async () => {
    try {
      // Fetch or create the subjectNode
      let subjectNode = await nodeGetOne({ subjectData: subjectUrl });
  
      // If the node does not exist, create a new one
      if (!subjectNode) {
        const basicNode = createNode(); // Create a new basic node
        basicNode.subjectData = subjectUrl;  // Assign subjectData
        subjectNode = basicNode; // Use the newly created node
      }
  
      // Now handle the collectedData
      if (subjectNode.emotionData) {
        // If emotionData exists, set it to collectedData
        setCollectedData(subjectNode.emotionData);
      } else {
        // If emotionData does not exist, reset collectedData (initialize it as needed)
        setCollectedData([]); // Assuming collectedData is an array; adjust if it's something else
      }
  
      // Set the subjectNode (existing or newly created)
      setsubjectNode(subjectNode);
    } catch (error) {
      console.error('Error in getData:', error);
    }
  };
  
  const setData = async () => {
    try {
      // First, ensure you have the current subjectNode
      if (!subjectNode) {
        console.error('No subjectNode is set, cannot save data.');
        return;
      }
  
      // Update the subjectNode with the latest collectedData or other data you want to save
      subjectNode.emotionData = collectedData; // Update or set emotionData
  
      // Save the updated subjectNode to the database
      const result = await nodePut(subjectNode); // nodePut function saves or updates the node in the database
  
      // Log the result for debugging
      console.log('Data saved successfully:', result);
    } catch (error) {
      console.error('Error in setData:', error);
    }
  };
  


  const updateSubjectUrl = (urlPostFix) => {
    const clientFolder = 'smurf/SUBJECTS'; // Set per client when needed

    // Ensure the base URL doesn't end with a slash and other parts don't start with slashes
    const cleanS3Base = S3_URL_BASE.replace(/\/$/, ''); // Remove trailing slash if present
    const cleanClientFolder = clientFolder.replace(/^\//, '').replace(/\/$/, ''); // Remove leading/trailing slashes
    const cleanUrlPostFix = urlPostFix.replace(/^\//, ''); // Remove leading slash if present

    //const url = `${cleanS3Base}/${cleanClientFolder}/${cleanUrlPostFix}`;
    const url = `${cleanS3Base}/${cleanUrlPostFix}`;
    //console.log('subjectUrl', url);

    setsubjectUrl(url);
  };


  const updateCurrentEmotionSet = (time) => {
    if (!emotionData || emotionData.length === 0) return;

    const maxTimestamp = Math.max(...emotionData.map(d => d.timestamp));
    const normalizedTime = (time / maxTimestamp) * maxTimestamp;

    const sampledEmotions = Object.keys(emotionColors).map(emotion => {
      const emotionSamples = emotionData.map(d => ({
        timestamp: d.timestamp,
        confidence: d.emotions.find(e => e.Type === emotion)?.Confidence || 0,
      }));

      const closestSample = emotionSamples.reduce((prev, curr) => {
        return Math.abs(curr.timestamp - normalizedTime) < Math.abs(prev.timestamp - normalizedTime)
          ? curr
          : prev;
      });

      return {
        label: emotion,
        value: closestSample.confidence,
      };
    });

    setCurrentEmotionSet(sampledEmotions);
  };

  const skip = (direction) => {
    if (direction === 'next') {
      setSelectedTime(selectedTimeRatio + increment);
    } else if (direction === 'previous') {
      setSelectedTime(selectedTimeRatio - increment);
    }
  };

  const formatTimecode = (selectedTimeRatio) => {
    const maxTimestamp = videoRefs.current.length > 0 ? videoRefs.current[0].duration * 1000 : 0; // Convert to milliseconds
    const milliseconds = selectedTimeRatio * maxTimestamp;
    const frames = 100;

    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const hundredths = Math.floor((milliseconds % 1000) / frames); // Get hundredths of a second

    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}:${String(hundredths).padStart(2, '0')}`;
  };

  const collectData = (emotion) => {
    const newData = {
      emotion,
      selectedTime: selectedTimeRatio,  // Use the percentage (selectedTimeRatio)
    };

    setCollectedData(prevData => [...prevData, newData]);
  };

  // Video control functions
  const playVideo = () => {
    setIsPlaying(true);
    videoRefs.current.forEach(videoRef => {
      if (videoRef) {
        videoRef.play();
      }
    });
  };

  const stopVideo = () => {
    setIsPlaying(false);
    videoRefs.current.forEach(videoRef => {
      if (videoRef) {
        videoRef.pause();
      }
    });
  };

  const resetVideo = () => {
    videoRefs.current.forEach(videoRef => {
      if (videoRef) {
        videoRef.currentTime = 0;
      }
    });
    setSelectedTime(0);
    setIsPlaying(false);
  };

  const resetData = () => {
    videoRefs.current.forEach(videoRef => {
      if (videoRef) {
        videoRef.currentTime = 0;
      }
    });
    setCollectedData([]); // Clear the collectedData
    resetVideo();
  };

  // New function to export collectedData to a file
  const exportCollectedDataToFile = (filename = 'collectedData.emo') => {
    const dataStr = JSON.stringify(collectedData);  // Convert collectedData to JSON string
    const dataBlob = new Blob([dataStr], { type: 'application/json' });  // Create a Blob from the JSON string
    const url = URL.createObjectURL(dataBlob);  // Create a URL for the Blob
    const link = document.createElement('a');  // Create a temporary link element
    link.href = url;
    link.download = filename;  // Set the file name for the download
    document.body.appendChild(link);  // Append the link to the document
    link.click();  // Programmatically click the link to trigger the download
    document.body.removeChild(link);  // Remove the link from the document
    URL.revokeObjectURL(url);  // Revoke the object URL to free up resources
  };

  // New function to import collectedData from a file
  const importCollectedDataFromFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const data = JSON.parse(event.target.result);  // Parse the file content as JSON
          setCollectedData(data);  // Update the collectedData state with the imported data
          resolve(data);
        } catch (error) {
          reject(error);  // Handle any errors during parsing
        }
      };
      reader.onerror = (error) => reject(error);  // Handle any errors during file reading
      reader.readAsText(file);  // Read the file content as text
    });
  };

  // New function to get collectedData
  const getCollectedData = () => {
    return collectedData;
  };

  // Function to get file tree from S3 and format it into a menuTree
  const getFileTree = async () => {
    try {
      const result = await listFiles('qwanyx-storage-images', 'SMURF/');
      const fileData = result.files;

      if (!Array.isArray(fileData)) {
        console.error('fileData is not an array:', fileData);
        return;
      }

      const seasons = fileData
        .filter(file => file.key.endsWith('/'))
        .map(file => file.key.split('/').slice(-2)[0])
        .filter(season => season !== 'SMURF' && season !== 'SEASONS'); // Filter out SMURF and SEASONS

      const seasonEpisodes = seasons.map(season => {
        const episodes = fileData
          .filter(file => file.key.startsWith(`SMURF/SEASONS/${season}/`) && !file.key.endsWith('/'))
          .map(file => file.key.split('/').slice(-1)[0]);

        return { season, episodes };
      });

      setMenuTree(seasonEpisodes); // Update the state with the filtered menu tree data
    } catch (error) {
      console.error('Error fetching file tree:', error);
    }
  };

  // Fetch seasons and episodes on component mount
  useEffect(() => {
    getFileTree();
  }, []);

  return (
    <EmotionContext.Provider
      value={{
        colors,
        emotionColors,
        emotionData,
        setEmotionData,
        selectedTime: selectedTimeRatio,
        setSelectedTime,
        currentEmotionSet,
        collectedData,
        setCollectedData,
        getCollectedData,
        importCollectedDataFromFile,  // Expose import function
        exportCollectedDataToFile,  // Expose export function
        formatTime: (milliseconds) => {
          const totalSeconds = Math.round(milliseconds / 1000);
          const minutes = Math.floor(totalSeconds / 60);
          const seconds = totalSeconds % 60;
          return `${String(minutes).padStart(3, '0')}:${String(seconds).padStart(2, '0')}`;
        },
        videoRefs,
        isPlaying,
        isScrubbing,
        playVideo,
        stopVideo,
        resetVideo,
        skip,
        collectData,
        menuTree,  // Expose seasons and episodes
        subjectUrl,
        updateSubjectUrl,
        getIdentity,
        resetData,
        setData,
        editMode
        // Expose build and set URL function
      }}
    >
      {children}
    </EmotionContext.Provider>
  );
};

export const useEmotion = () => useContext(EmotionContext);
