import React from 'react';
import { FormGroup, FormControlLabel, Checkbox, Radio, TextField, Stack, RadioGroup, MenuItem, Select, InputLabel, FormControl } from '@mui/material';


export default function DashBoardContact({ node, onChange }) {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onChange(name, value);
    };

    return (
        <FormGroup>
            <Stack width="100%" spacing={1.5} padding={1.5}>
            <TextField
                    label="Team"
                    name="team"
                    value={node?.team || ''}
                    onChange={handleInputChange}
                />
            <TextField
                    label="Nome de la rue"
                    name="street"
                    value={node?.street || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Numero"
                    name="number"
                    value={node?.number || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Boite"
                    name="unit"
                    value={node?.unit || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Site web"
                    name="postalCode"
                    value={node?.postalCode || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Ville"
                    name="city"
                    value={node?.city || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Pays"
                    name="country"
                    value={node?.country || ''}
                    onChange={handleInputChange}
                />
            
                <TextField
                    label="Email"
                    name="email"
                    value={node?.email || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Téléphone"
                    name="phone"
                    value={node?.phone || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Site web"
                    name="website"
                    value={node?.website || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Linked-in"
                    name="linkedin"
                    value={node?.linkedin || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Facebook"
                    name="facebook"
                    value={node?.facebook || ''}
                    onChange={handleInputChange}
                />
            </Stack>
        </FormGroup>
    );
}
