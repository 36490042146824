import * as React from 'react';

import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Stack,
    Box,
    Typography,
} from '@mui/material';


const Intro = `Your Gateway to Infinite Productivity.
Here, we're redefining the digital workspace with our innovative "Infinite Desktop," where your ideas, projects, and collaborations know no bounds.
Unleash your full potential in a seamless and interconnected environment, designed to bring out the best in your work and creative endeavors.
Dive into Qwanyx and experience the freedom to create, manage, and collaborate like never before.`

export default function QwanyxWelcomeCard({ maxWidth = '950px', imageSize = '250px' }) {
    return (
        <Card sx={{ maxWidth }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}> {/* Adjusted flexDirection typo and added height */}
                <CardMedia
                    component="img"
                    sx={{
                        width: imageSize,
                        objectFit: 'cover'
                    }}
                    image="https://qwanyx-storage-images.s3.eu-central-1.amazonaws.com/GPT/images/node_6639c31c9ea80a886ae59412.png"
                    title="green iguana"
                />
                <Stack sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}> {/* Ensured Stack takes available space */}
                    <CardContent sx={{ flexGrow: 1 }}> {/* Ensures content uses available space pushing actions down */}
                        <Typography gutterBottom variant="h5" component="div">
                            Welcome to Qwanyx
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {Intro}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ mt: 'auto' }}> {/* Forces CardActions to the bottom */}

                        {/* Share button 
                        <Button size="small">Share</Button>
                        <Button size="small">Learn More</Button>

                        */}
                    </CardActions>
                </Stack>
            </Box>
        </Card>
    );
}
