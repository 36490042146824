import React, { createContext, useState, useContext } from 'react';
import { nodesGet, createNode, getUserId } from '../MlModels/MlNode';

// Create the UserContext
export const UserContext = createContext();

// Create a provider component for the UserContext
export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const type = 'userData'

    // Function to fetch user data based on identity
    const fetchUserData = async (identity) => {
        try {
            const query = { userId: getUserId(), identity, type };
            const fetchedNodes = await nodesGet(query);
            
            if (fetchedNodes && fetchedNodes.length > 0) {
                setUserData(fetchedNodes[0]); // Set the first node
            } else {
                setUserData(createNode({ title:'', type: type, identity: identity})); // Create a new node if none found
            }
        } catch (error) {
            console.error('Failed to fetch user data:', error);
        }
    };

    return (
        <UserContext.Provider
            value={{
                userData,
                setUserData,
                fetchUserData,  // Expose the function to be used by components
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

// Custom hook to use the UserContext
export const useUser = () => {
    return useContext(UserContext);
};
