const markdownContent = `
### **Conditions Générales d'Utilisation**

**Dernière mise à jour : 12/09/2024**

#### **1\. Introduction**

Bienvenue sur \AUTODIN, une plateforme en ligne dédiée à la recherche de véhicules à acheter. En accédant à notre site, vous acceptez d'être lié par les présentes conditions générales. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre site.

#### **2\. Définitions**

* **Plateforme** : désigne le site web et les services proposés par \AUTODIN\.  
* **Utilisateur** : désigne toute personne qui accède à la Plateforme.  
* **Annonceur** : désigne toute personne physique ou morale qui publie une annonce de vente de véhicule sur la Plateforme.  
* **Véhicule** : désigne tout véhicule proposé à la vente sur la Plateforme.

#### **3\. Accès et Utilisation de la Plateforme**

3.1. **Accès** : L'accès à la Plateforme est gratuit pour tous les Utilisateurs. Toutefois, certaines fonctionnalités peuvent être réservées aux utilisateurs inscrits.

3.2. **Utilisation** : La Plateforme permet aux Utilisateurs de rechercher des véhicules à acheter, de consulter les annonces, et de contacter les Annonceurs.

3.3. **Inscription** : Pour accéder à certaines fonctionnalités, l'Utilisateur peut être amené à créer un compte. L'Utilisateur s'engage à fournir des informations exactes et à maintenir la confidentialité de ses identifiants de connexion.

#### **4\. Annonces et Contenu**

4.1. **Publication d'annonces** : Les Annonceurs peuvent publier des annonces de vente de véhicules sur la Plateforme. Ils s'engagent à fournir des informations véridiques et précises sur les véhicules proposés.

4.2. **Responsabilité du contenu** : L'Annonceur est seul responsable du contenu de son annonce. La Plateforme ne garantit pas l'exactitude des informations fournies par les Annonceurs.

4.3. **Modération** : La Plateforme se réserve le droit de modérer, de refuser ou de supprimer toute annonce qui ne respecte pas les présentes conditions générales ou qui contrevient à la législation en vigueur.

#### **5\. Transactions**

5.1. **Nature de la plateforme** : La Plateforme agit uniquement en tant qu'intermédiaire entre l'Utilisateur et l'Annonceur. La Plateforme ne participe pas aux transactions de vente et n'assume aucune responsabilité en cas de litige entre l'Utilisateur et l'Annonceur.

5.2. **Vérification des véhicules** : Il est de la responsabilité de l'Utilisateur de vérifier l'état, l'origine, et les caractéristiques du véhicule avant toute transaction.

#### **6\. Données Personnelles**

6.1. **Collecte des données** : Les données personnelles des Utilisateurs sont collectées et traitées conformément à notre politique de confidentialité.

6.2. **Utilisation des données** : Les données collectées sont utilisées pour le fonctionnement de la Plateforme, l'amélioration des services et pour des fins commerciales.

#### **7\. Limitation de Responsabilité**

7.1. **Disponibilité** : La Plateforme met tout en œuvre pour assurer la disponibilité du site. Toutefois, elle ne peut être tenue responsable en cas de dysfonctionnement ou d'interruption du service.

7.2. **Exclusion de garantie** : La Plateforme est fournie "en l'état" sans aucune garantie de quelque nature que ce soit. La responsabilité de la Plateforme ne saurait être engagée pour des dommages indirects résultant de l'utilisation du site.

#### **8\. Propriété Intellectuelle**

Tous les éléments présents sur la Plateforme, y compris les textes, images, logos, sont protégés par le droit de la propriété intellectuelle. Toute reproduction ou utilisation non autorisée de ces éléments est strictement interdite.

#### **9\. Modification des Conditions Générales**

La Plateforme se réserve le droit de modifier les présentes conditions générales à tout moment. Les modifications seront publiées sur le site et entreront en vigueur dès leur mise en ligne.

#### **10\. Loi Applicable et Juridiction**

Les présentes conditions générales sont régies par la loi Belge. Tout litige relatif à l'utilisation de la Plateforme sera soumis à la compétence exclusive des tribunaux du ressort de Liège.

#### **11\. Contact**

Pour toute question relative aux présentes conditions générales, vous pouvez nous contacter à l'adresse suivante : info@autodin.be.
`;

export default markdownContent;
