import React from 'react';

const QwanyxLogoWhite = ({ height = '100%' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 918.5 259.1" style={{ height: height, width: 'auto' }}>
      <defs>
        <style>
          {`.cls-1 { fill: #aaa; }
           .cls-1, .cls-2 { stroke-width: 0px; }
           .cls-2 { fill: #fff; }`}
        </style>
      </defs>
      <g>
        <path class="cls-1" d="M213.4,197.4c-35.6,35.6-88.6,43.1-131.6,22.6l27.2-27.2-54.5-54.5-27.2,27.2C6.7,122.5,14.3,69.5,49.9,33.9c45.2-45.2,118.4-45.2,163.5,0,45.2,45.2,45.2,118.4,0,163.5Z" />
        <path class="cls-2" d="M81.8,220l-27.3,27.3L0,192.8l27.3-27.3c5.5,11.5,13,22.4,22.6,31.9,9.6,9.6,20.4,17.1,31.9,22.6Z" />
      </g>
      <g>
        <path class="cls-2" d="M437.7,111.6l-38.2,107.3h-36.4l-20.2-58.4-21,58.4h-36.4l-38.2-107.3h35.6l22,64.9,22.8-64.9h32.1l22.2,65.5,22.8-65.5h33.1Z" />
        <path class="cls-2" d="M526.3,122c9.4,8.1,14.1,20.6,14.1,37.3v59.6h-35v-13.9c-5.4,10.4-16,15.6-31.7,15.6s-15.5-1.5-21.6-4.4c-6.1-2.9-10.7-6.8-13.8-11.8-3.1-5-4.7-10.6-4.7-16.9,0-10.2,3.9-18,11.7-23.7,7.8-5.6,19.8-8.4,36-8.4h21.4c-.7-11.4-8.3-17-22.8-17s-10.4.8-15.6,2.5c-5.3,1.7-9.8,3.9-13.5,6.8l-12.7-25.5c5.9-4,13.2-7,21.7-9.2,8.5-2.2,17.1-3.3,25.6-3.3,17.8,0,31.4,4.1,40.8,12.2ZM495.6,193.8c3.3-2.1,5.7-5.2,7.1-9.3v-9.3h-16.2c-11,0-16.4,3.6-16.4,10.9s1.3,5.9,3.8,7.9c2.5,2,5.9,3,10.3,3s8.2-1.1,11.5-3.2Z" />
        <path class="cls-2" d="M654.5,121.7c8.1,7.9,12.2,19.9,12.2,35.8v61.4h-37.6v-55.2c0-14.7-5.9-22-17.6-22s-11.7,2.1-15.5,6.3c-3.9,4.2-5.8,10.6-5.8,19v51.9h-37.6v-107.3h35.8v11.7c4.2-4.4,9.2-7.7,15-10,5.8-2.3,12.1-3.5,19-3.5,13.3,0,24.1,4,32.2,11.9Z" />
        <path class="cls-2" d="M791.1,111.6l-46.3,111.7c-5.4,13.2-12,22.5-19.8,27.8-7.8,5.3-17.3,8-28.5,8s-11.4-.9-17.1-2.7c-5.7-1.8-10.4-4.1-14-7l13.1-26.3c2.1,2,4.7,3.5,7.6,4.7,3,1.1,6,1.7,9,1.7s6.5-.7,8.8-2.2c2.3-1.5,4.3-3.8,5.8-6.9l-45.9-108.7h38.6l26.3,65.5,26.5-65.5h35.8Z" />
        <path class="cls-2" d="M875.5,218.9l-19.2-28.1-20.6,28.1h-40.8l40.8-53.7-39.6-53.7h42.4l19,27.1,19.8-27.1h39.8l-39.6,52.3,41,55h-43Z" />
      </g>
    </svg>
  );
};

export default QwanyxLogoWhite;
